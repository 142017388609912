/* globals List */
/* exported pxlAccordion */

class pxlAccordion {
  constructor( selector ) {
    this.$          = document.getElementById(selector);
    this.$accordion = this.$.querySelector('.accordion');
    this.$filters   = this.$.querySelector('.accordion-types');
    
    this.options = {
      searchClass: 'accordion-search__input',
      listClass: 'accordion',
      valueNames: [
        'acc-title',
        'acc-content',
        { data: ['types'] }
      ]
    };
    
    this.list = new List(selector, this.options);
    this.search = document.querySelector('.'+this.options.searchClass);
    
    if ( this.search && this.search.value ) {
      var list = this.list;
      this.list.on('searchComplete', function() {
        list.visibleItems[0].elm.classList.add('open')
      });
      
      list.search(this.search.value);
    }
    
    this.init();
    this.listeners();
  }
  init() {
    var navbar = document.querySelector('.navbar');
    if ( navbar ) this.$.style.setProperty('--offset', navbar.offsetHeight + 18 + 'px');
  }
  listeners() {
    var _this = this, link = window.location.href.split('#');
    
    if ( link[1] ) {
      var item = document.getElementById(link[1]);
      this.toggle(item, false);
    }
    
    this.$accordion.addEventListener('click', function(e) {
      if ( e.target && e.target.classList.contains('acc-title') ) _this.toggle(e.target.parentElement, e);
    }, true);
    
    if ( this.$filters ) {
      this.$filters.addEventListener('click', function(e) {
        if ( e.target && e.target.classList.contains('accordion-types__type') ) {
          e.preventDefault();
          
          if ( e.target.classList.contains('active') ) {
            e.target.classList.remove('active');
            _this.list.filter(function() { return true; });
          }
          else {
            var sibling = e.target.parentElement.querySelector('.active');
            if ( sibling ) sibling.classList.remove('active');
            
            e.target.classList.add('active');
            _this.list.filter(function(item) {
              return item.values().types.includes(e.target.dataset.type);
            });
          }
          
          return false;
        }
      });
    }
  }
  toggle( item, e ) {
    if ( item.classList.contains('open') ) item.classList.remove('open');
    else if ( e ) {
      if ( this.$accordion.classList.contains('automatic') ) {
        this.$accordion.querySelectorAll('.acc-item.open').forEach(function( item ) {
          if ( item != e.target ) item.classList.remove('open');
        });
      }
      item.classList.add('open');
    }
  }
}