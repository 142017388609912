/* globals jQuery */
/* exported pxlGallery */
class pxlGallery {
  constructor( selector ) {
    jQuery( document ).ready(function() {
      jQuery(selector).magnificPopup({
          delegate: 'a', // the selector for gallery item
          type: 'image',
          gallery: {
            enabled:true
          }
      });
    });
  }
}